import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout/ca"
import SEO from "../components/seo"
import HeroImage from "../components/ui-sections/hero-image"
import TextMosaic from "../components/ui-sections/text-mosaic"
import TextSection from "../components/ui-sections/text-section"
import ImageGallery from "../components/ui-sections/image-gallery"

const OrigensPage = (props) => {
	const heroText = "Origens";
	const sentenceText = "Tot comença el juny de 1969";
	const titleAvi = "Tot comença el juny de 1969";
	const titlePare = "El gran salt, mantenint l'essència";
	const titlePere = "El segell gastronòmic del Pere";
	const contentAvi = "El juny de 1969 l'avi Pere i la iaia Carmeta inauguraven Cal Pere del Maset. El restaurant ja des del primer dia es va fer inolvidable per la família Palau Sardà, on la filla va celebrar el seu casament el dia de la inauguració. I aquell restaurant de carn a la brasa, tirat endavant pels avis, i dos petits marrecs com eren llavors el pare i l'onclo, va anar augmentant la capacitat dels menjadors, de la cuina, i en conseqüència la carta. Amb un ast de llenya es cuinaven espatlles, cabrits, conills i també van néixer els guisats i forn que trobem avui a la carta. Els avis van posar els fonaments del que és el restaurant avui dia. Els fonaments de tres generacions diferents fins a dia d'avui.";
	const contentPare = "El pare va agafar el relleu de l'avi, i ell i la mare han fet del restaurant un referent de l'Alt Penedès, de la cuina del millor producte. Entre els anys 80 i els 2000, el restaurant va créixer molt amb la creació dels menjadors privats, de la bodega, i de la renovació sencera de la cuina i els menjadors. Però mantenint l'ànima, l'essència del primer dia. Cal Pere del Maset és un lloc on s'acomulen records de tota mena, generacions de famílies que venien amb els avis i ara venen amb els fills. De bon menjar i de bon beure, però també de nostàlgia i de sentiments. I aquesta essència és la que el pare i la mare li han donat sempre amb la seva personalitat. Seguint el llegat dels avis, perquè els nostres clients, amics, coneguts i desconeguts vinguin a menjar i se sentin com a casa."
	const contentPere = "El Pere és la tercera generació, i qui avui lidera, juntament amb la mare, el dia a dia del restaurant. Crescut entre brases i fogons, des de ben jove sempre va tenir clar que volia dedicar-se a la cuina. Va començar torrant el pa amb només 9 anys, i va créixer combinant els estudis amb ajudar sempre a la cuina. Un cop acabats els estudis, es va anar a formar a restaurants de prestigi del món de l'Estrella Michelin, com Les Crayères (Reims, França) o el Drolma del Xef Fermí Puig a Barcelona. I avui, des de fa ja més d'una dècada, manté una oferta gastronòmica de temporada, combinant la carta de tota la vida amb menús degustació, plats de temporada i maridatges pels paladars més exigents. I com els avis, el pare i la mare, mantenint el tracte pròxim i familiar."
	const textSectionText1 = "\"Els origens són els avis. L'estil, la brasa, el foc, els guisats, el sofregit, el pa torrat, el tacte, el tracte l'esforç i el sacrifici, el caràcter, l'empenta, la lluita, la vida, part del que som i del que vindrà. Els avis. El nostre estimat avi Pere i la nostra estimada iaia Carmeta.\""
	const textSectionText2 = "\"Pit i collons.\" De l'avi al pare, i del pare al Pere i jo."


	return (
	  <Layout location={props.location}>
	    <SEO 
	    	title="Origens"
	    	lang="ca"
	    	description={textSectionText1}
	    />
		  <HeroImage 
		  	titleText={heroText}
		  	sentenceText={sentenceText}
		  	image="casa_antiga"
		  />
		  <TextSection textSectionText={textSectionText1} />
	    <StaticQuery
	      query={graphql`
	        {
	          allOrigensImagesCaYaml {
	            edges {
	              node {
	                title
	                image {
	                	id
	                  childImageSharp {
	                    fluid {
	                      aspectRatio
	                      originalImg
	                      # Use gatsby-image later for better user experience
	                      ...GatsbyImageSharpFluid
	        						}	
	        					}
	        				}
	        			}
	        		}
	        	}
	      	}
	      `}
	      render={data => (
	        <ImageGallery
	          itemsPerRow={[1, 3]} // This will be changed to `[2, 3]` later
	          images={data.allOrigensImagesCaYaml.edges.map(({ node }) => ({
	          	id: node.image.id,
	            ...node.image.childImageSharp.fluid,
	            caption: `${node.title}`,
	          }))}
	        />
	      )}
	    />
	    <TextMosaic 
	    	titleAvi={titleAvi}
	    	titlePare={titlePare}
	    	titlePere={titlePere}
	    	contentAvi={contentAvi}
	    	contentPare={contentPare}
	    	contentPere={contentPere}
	    />
	    <TextSection textSectionText={textSectionText2} />
	    <HeroImage  image="paisatge" />
	  </Layout>
	)
}

export default OrigensPage
