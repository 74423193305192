import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import Img from "gatsby-image"

const TextMosaic = (props) => {
	
	const data = useStaticQuery(graphql`
		query {
			avi_antiga: file(relativePath: { eq: "origens/avi_antiga.jpg" }) {
			  childImageSharp {
			    # Specify the image processing specifications right in the query.
			    fluid(maxWidth: 400) {
			      ...GatsbyImageSharpFluid_withWebp
			    }
			  }
			}
			pare_carn: file(relativePath: { eq: "origens/pare_carn.jpg" }) {
			  childImageSharp {
			    # Specify the image processing specifications right in the query.
			    fluid(maxWidth: 400) {
			      ...GatsbyImageSharpFluid_withWebp
			    }
			  }
			}
			pere_peix: file(relativePath: { eq: "origens/pere_peix.jpg" }) {
			  childImageSharp {
			    # Specify the image processing specifications right in the query.
			    fluid(maxWidth: 400) {
			      ...GatsbyImageSharpFluid_withWebp
			    }
			  }
			}
		}
	`)

	return (
		<Mosaic>
			<MosaicRow>
				<MosaicColumn>
					<h1>{props.titleAvi}</h1>
					<p>{props.contentAvi}</p>
				</MosaicColumn>
				<MosaicColumn>
					<h1>{props.titlePare}</h1>
					<p>{props.contentPare}</p>
				</MosaicColumn>
				<MosaicColumn>
					<h1>{props.titlePere}</h1>
					<p>{props.contentPere}</p>
				</MosaicColumn>
			</MosaicRow>
			<MosaicRow>
				<MosaicColumn>
					<Img
					  fluid={data.avi_antiga.childImageSharp.fluid}
					  alt="L'avi Pere amb un peix"
					  loading="lazy"
					/>
				</MosaicColumn>
				<MosaicColumn>
					<Img
					  fluid={data.pare_carn.childImageSharp.fluid}
					  alt="El pare amb un tros de carn"
					  loading="lazy"
					/>
				</MosaicColumn>
				<MosaicColumn>
					<Img
					  fluid={data.pere_peix.childImageSharp.fluid}
					  alt="El Pere amb un peix"
					  loading="lazy"
					/>
				</MosaicColumn>
			</MosaicRow>
		</Mosaic>
	)
}


const Mosaic = styled.section`
	display: flex;
	flex-direction: column;
`

const MosaicRow = styled.div`
	display: flex;
	padding: 5px 20px;
	@media (max-width: 993px){
		flex-direction: column;
		padding: 0;
	}
`

const MosaicColumn = styled.div`
	width: 100%;
	margin: 5px 40px;
	@media (max-width: 993px){
		margin: 0;
		width: auto;
	}
`

export default TextMosaic;